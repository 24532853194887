<template>
  <!-- send-invoice-modal -->
  <b-modal
      id="record-payment-modal"
      ref="RecordPaymentModal"
      title="Record payment"
      size="md"
      header-class="font-primary"
      header-bg-variant="light"
      header-text-variant="main-green"
      header-border-variant="white"
      footer-bg-variant="light"
      footer-border-variant="white"
      v-model="showModal"
      @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
          class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <b-button
            variant="gray-550"
            pill
            class="my-2 my-sm-0 mr-0 mr-sm-2 border-0 bg-ghost-white action-btn"
            @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
            v-if="payAmount && payAmount>0"
            variant="main-green"
            pill
            class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn"
            @click="addTransaction()"
        >
          Save
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
          icon="x-circle"
          class="text-dark"
          @click="closeModal()"
          aria-label="Close Modal"
          font-scale="0.8"
      ></b-icon>
    </template>
    <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100 my-3 my-md-2 px-1 py-1"
        no-gutters
    >
      <!-- full row input (Customer) -->
      <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 px-2 pb-2"
          no-gutters
      >
        <b-col cols="4">
          <span class="text-left text-dark font-primary font-weight-normal"
          >Payment date</span
          >
        </b-col>
        <b-col
            class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
            cols="6"
        >
          <b-input-group>
            <b-form-input
                id="example-input"
                :value="formatDate(paymentDate)"
                type="text"
                class="date-picker"
                :invalid-feedback="paymentDateError.message"
                :state="paymentDateError.status"
                placeholder=""
                autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                  class="date-picker-btn"
                  v-model="paymentDate"
                  button-only
                  button-variant="white"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                  nav-button-variant="main-green"
                  today-variant="main-green"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <!-- full row input (Full name) -->
      <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
          no-gutters
      >
        <b-col cols="4">
          <span class="text-left text-dark font-primary font-weight-normal"
          >Amount ({{ itemContent.currency ? itemContent.currency : itemContent.totalAmount.currency }})</span
          >
        </b-col>
        <b-col cols="8">
          <FormInput
              groupId="user-name-group"
              id="user-name-group-input"
              class="text-prime-gray rounded-2"
              v-model="payAmount"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
              :invalid-feedback="payAmountError.message"
              :state="payAmountError.status"
          ></FormInput>
          <h6
              class="text-left text-truncate text-small font-weight-bold text-main-green"
              v-if="conversion_rate && payAmount"
          >
            Currency conversion {{ (payAmount / conversion_rate).toFixed(2) }}
            ({{ anchor_currency }}) at {{ conversion_rate }}
          </h6>
        </b-col>
      </b-row>
      <!-- full row input (Payment method) -->
      <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
          no-gutters
      >
        <b-col cols="4">
          <span class="text-left text-dark font-primary font-weight-normal"
          >Payment method</span
          >
        </b-col>
        <b-col cols="8">
          <FormSelect
              groupId="fiscalyear-group"
              id="fiscalyear-group-input"
              class="text-prime-gray rounded-2"
              v-model="paymentMethod"
              :whiteBG="false"
              :options="paymentMethodOptions"
              form="login-form"
              :invalid-feedback="paymentMethodError.message"
              :state="paymentMethodError.status"
          ></FormSelect>
        </b-col>
      </b-row>
      <!-- full row input (Payment account) -->
      <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
          no-gutters
      >
        <b-col cols="4">
          <span class="text-left text-dark font-primary font-weight-normal"
          >Payment account</span
          >
        </b-col>
        <b-col cols="8">
          <FormSelect
              :invalid-feedback="accountTypeError.message"
              :state="accountTypeError.status"
              groupId="fiscalyear-group"
              id="fiscalyear-group-input"
              class="text-prime-gray rounded-2"
              v-model="accountType"
              :whiteBG="false"
              :options="accountTypeOptions"
              form="login-form"
          ></FormSelect>
        </b-col>
      </b-row>
      <!-- full row input (E-mail) -->
      <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
          no-gutters
      >
        <b-col cols="4">
          <span class="text-left text-dark font-primary font-weight-normal"
          >Note</span
          >
        </b-col>
        <b-col cols="8">
          <FormInput
              :invalid-feedback="paymentNoteError.message"
              :state="paymentNoteError.status"
              groupId="user-name-group"
              id="user-name-group-input"
              class="text-prime-gray rounded-2"
              v-model="paymentNote"
              isRequired
              isTextArea
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
          ></FormInput>
        </b-col>
      </b-row>
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
// services
import {
  GetCashAndBank,
  AddTransaction,
} from "@/services/transactions.service";

export default {
  name: "RecordPaymentModal",
  components: {
    FormInput,
    FormSelect,
  },
  props: {
    itemContent: {
      default: null,
      required: true,
    },
    showModal:{
      default: false,
      required: true,
    },
    customer:Boolean,
  },
  data() {
    return {
      anchor_currency: null,
      conversion_rate: null,
      paymentDate: null,
      payAmount: null,
      paymentMethod: null,
      paymentNote: null,
      accountType: null,
      paymentMethodOptions: ["Cash payment", "Card payment", "Bank transfers"],
      accountTypeOptions: [],
      paymentDateError: {
        message: "",
        status: null,
      },
      payAmountError: {
        message: "",
        status: null,
      },
      paymentMethodError: {
        message: "",
        status: null,
      },
      paymentNoteError: {
        message: "",
        status: null,
      },
      accountTypeError: {
        message: "",
        status: null,
      },
    };
  },
  watch: {
    paymentDate() {
      this.paymentDateError.status = null;
    },
    payAmount() {
      this.payAmountError.status = null;
      console.log(this.itemContent)
    },
    paymentMethod() {
      this.paymentMethodError.status = null;
    },
    paymentNote() {
      this.paymentNoteError.status = null;
    },
    accountType() {
      this.accountTypeError.status = null;
    },
    itemContent(val) {
      this. initFn()
      this.conversions(val);
    },

  },
  computed: {},
  async mounted() {
    this.conversions(this.itemContent);
    await this.initFn();
  },
  methods: {
    conversions(val){
      if (this.$route.fullPath !== '/invoices') {
        this.payAmount = Number(val.amount_due).toFixed(2);
        this.anchor_currency = val.anchor_currency;
        this.conversion_rate = Number(val.conversion_rate);
      } else {
        this.payAmount = Number(val.totalAmount.totalAmount).toFixed(2);
        this.anchor_currency = val.totalAmount.anchor_currency;
        this.conversion_rate = Number(val.totalAmount.conversion_rate);
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
    async initFn() {
      // get cash and bank
      try {
        let {data} = await GetCashAndBank();
        this.accounts = data.data;
        let accountsArray = [];
        if (this.accounts.length > 0) {
          this.accounts.forEach((account) => {
            console.log(account)
            accountsArray.push({
              value: {
                type: account.group,
                subcategory: account.subgroup,
                account: account.account,
                id:account.id
              },
              text: account.account,
            });
          });

          this.accountTypeOptions = [
            {
              label: this.accounts[0] ? this.accounts[0].subgroup : "",
              options: accountsArray,
            },
          ];
        }
        if (this.accounts.length === 0) {
          this.accountTypeError.message = "Please create a bank account in COA";
          this.accountTypeError.status = false;
        }


      } catch (error) {
        console.log(error);
      }
    },
    addTransaction() {
      var result = this.validateForm();
      if (result.length === 0) {
        let payload = {
          date: this.paymentDate,
          total_amount: this.conversion_rate ? Number(this.payAmount / this.conversion_rate)
              .toFixed(2) : Number(this.payAmount).toFixed(2),
          paymentMethod: this.paymentMethod,
          notes: this.paymentNote,
          account: `${this.accountType.type},${this.accountType.subcategory},${this.accountType.account}`,
          // category: `${this.accountType.type},${this.accountType.subcategory},${this.accountType.account}`,
          category: this.customer ? 'income,uncategorized income,uncategorized income':'expenses,uncategorized expense,uncategorized expense',
          account_id: this.accountType.id,
          description: this.itemContent.title,
          invoice: this.itemContent.actions.id,
          is_invoice_transaction: true,
        };
        AddTransaction(payload)
            .then((res) => {
              console.log(res);
              this.closeModal();
              let payloadNotify = {
                isToast: true,
                title: "Success",
                content: "Transaction created!",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
            })
            .catch((err) => {
              console.log(err);
              let payloadNotify = {
                isToast: true,
                title: "Error: Unable to save the Transaction",
                content: "Something went wrong!\n Please try again.",
                variant: "danger",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
            });
            this.$emit('reloadFn');
            this.paymentDate = null,
            this.paymentMethod = null,
            this.paymentNote = null,
            this.accountType = null,
            this.paymentMethodOptions =[]
      }
      
    },
    validateForm() {
      var errorList = [];
      if (!this.paymentDate) {
        this.paymentDateError.message = "Please select a valid Date";
        this.paymentDateError.status = false;
        errorList.push("Please select a valid Date");
        let payloadNotify = {
          isToast: true,
          title: "Validation Error:",
          content: "Date seems to be invalid!",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.payAmount) {
        this.payAmountError.message = "Please fill payment amount";
        this.payAmountError.status = false;
        errorList.push("Please fill payment amount");
        let payloadNotify = {
          isToast: true,
          title: "Validation Error:",
          content: "Please enter payment amount.",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } else if (isNaN(this.payAmount)) {
        this.payAmountError.message = "Total Amount must be an number";
        this.payAmountError.status = false;
        errorList.push("Total Amount must be an number");
        let payloadNotify = {
          isToast: true,
          title: "Validation Error:",
          content: "Double check the amount. Seems to be invalid!",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.paymentMethod) {
        this.paymentMethodError.message = "Please select a payment method";
        this.paymentMethodError.status = false;
        errorList.push("Please select a payment method");
        let payloadNotify = {
          isToast: true,
          title: "Validation Error:",
          content: "Select a payment method.",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } else if (!this.paymentMethodOptions.includes(this.paymentMethod)) {
        this.paymentMethodError.message =
            "Selected payment method is not valid";
        this.paymentMethodError.status = false;
        errorList.push("Selected payment method is not valid");
        let payloadNotify = {
          isToast: true,
          title: "Validation Error:",
          content: "Payment method is invalid!",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.paymentNote) {
        this.paymentNoteError.message = "Please fill the payment note";
        this.paymentNoteError.status = false;
        errorList.push("Please fill the payment note");
        let payloadNotify = {
          isToast: true,
          title: "Validation Error:",
          content: "Add some notes for the payment record.",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.accountType) {
        this.accountTypeError.message = "Please select a account type";
        this.accountTypeError.status = false;
        errorList.push("Please select a account type");
        let payloadNotify = {
          isToast: true,
          title: "Validation Error:",
          content: "Account type is required!",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      return errorList;
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },

};
</script>

<style scoped>
.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.date-picker-btn {
  border: 1.8px solid;
  border-color: var(--gray-500);
  border-radius: 0px 3px 3px 0px;
  height: 39px;
}

.tab-wrapper {
  border-bottom: 2px solid var(--gray-750);
  /* border-bottom: none; */
}

.customer-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
}

.customer-tabs >>> .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
