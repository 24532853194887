<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <!-- user insights row -->
          <b-row
            class="d-flex flex-row align-items-stretch justify-content-center w-100"
            no-gutters
          >
            <b-card
              no-body
              class="position-relative overflow-hidden shadow-sm border-3 border-main-green insight-cards-row w-100"
            >
              <template v-if="isAnalyticsLoading">
                <b-overlay :show="isAnalyticsLoading" no-wrap variant="white">
                </b-overlay>
              </template>
              <template v-else>
                <b-row
                  class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-4 px-3 w-100"
                  no-gutters
                  v-if="!isQuote"
                >
                  <!-- Overdue column -->
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <h6
                      class="text-left text-truncate text-small text-main-green"
                    >
                      Overdue
                    </h6>
                    <h5
                      class="text-left font-secondary font-weight-bold text-dark"
                    >
                      {{
                        analytics.amountOverdue
                          ? numberFormat(analytics.amountOverdue)
                          : 0
                      }}
                      {{ currency }}
                    </h5>
                  </b-col>
                  <!-- Due within next 30 days column -->
                  <template>
                    <b-col
                      class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                      cols="12"
                      md="6"
                      lg="3"
                    >
                      <h6
                        class="text-left text-truncate text-small text-main-green"
                      >
                        Due within next 30 days
                      </h6>
                      <h5
                        class="text-left font-secondary font-weight-bold text-dark"
                      >
                        {{
                          analytics.monthOverdue
                            ? numberFormat(analytics.monthOverdue)
                            : 0
                        }}
                        {{ currency }}
                      </h5>
                    </b-col>
                  </template>
                  <!-- Average time column -->
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <h6
                      class="text-left text-truncate text-small text-main-green"
                    >
                      Average time to get paid
                    </h6>
                    <h5
                      class="text-left font-secondary font-weight-bold text-dark"
                    >
                      {{
                        analytics.avgTime ? analytics.avgTime + " days" : "N/A"
                      }}
                    </h5>
                  </b-col>
                  <!-- Reaming invoice number -->
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <h6
                      class="text-left text-truncate text-small text-main-green"
                    >
                      Remaining no. of invoices
                    </h6>
                    <h5
                      class="text-left font-secondary font-weight-bold text-dark"
                    >
                      {{ limits.remaining_invoice }}
                    </h5>
                  </b-col>
                  <!-- Upcoming payout column -->
                  <!-- <b-col
                    class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <h6
                      class="text-left text-truncate text-small font-weight-bold text-info"
                    >
                      Upcoming payout
                    </h6>
                    <h5 class="text-left font-secondary font-weight-bold text-dark">
                      {{ analytics.upcomingPayout }}
                    </h5>
                  </b-col> -->
                </b-row>
                <b-row
                  class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-4 px-3 w-100"
                  no-gutters
                  v-else
                >
                  <!-- Overdue column -->
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <h6
                      class="text-left text-truncate text-small text-main-green"
                    >
                      Sent
                    </h6>
                    <h5
                      class="text-left font-secondary font-weight-bold text-dark"
                    >
                      {{
                        analytics.totalSentAmount
                          ? numberFormat(analytics.totalSentAmount)
                          : 0.0
                      }}
                      {{ currency }}
                    </h5>
                  </b-col>
                  <!-- Due within next 30 days column -->
                  <template>
                    <b-col
                      class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <h6
                        class="text-left text-truncate text-small text-main-green"
                      >
                        Unsent
                      </h6>
                      <h5
                        class="text-left font-secondary font-weight-bold text-dark"
                      >
                        {{
                          analytics.totalUnsentAmount
                            ? numberFormat(analytics.totalUnsentAmount)
                            : 0.0
                        }}
                        {{ currency }}
                      </h5>
                    </b-col>
                  </template>
                </b-row>
              </template>
            </b-card>
          </b-row>
          <!-- table container row -->
          <b-row
            class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
            no-gutters
          >
            <!-- Trial Banner -->
            <b-alert
              v-if="!accountStatus && !isQuote"
              style="
                background-color: rgba(11, 155, 125, 0.5);
                color: black;
                border-radius: 10px;
              "
              dismissible
              :show="true"
              @dismissed="dismissBanner"
            >
              Pay Your Invoices Online through Figa, Please Connect Your Stripe
              Merchant Account from the Subscription Feature on Profile Settings
            </b-alert>

            <b-row
              class="d-flex flex-row align-items-stretch justify-content-center w-100"
              no-gutters
            >
              <!-- <div>
              <b-tabs content-class="mt-3" align="right">
                <b-tab title="Customers" active class="text-danger"></b-tab>
                <b-tab title="Suppliers"></b-tab>
              </b-tabs>
            </div> -->
              <div>
                <b-tabs
                  active-nav-item-class="font-weight-bold text-uppercase text-primary text-color"
                  v-model="tabIndex"
                >
                  <b-tab active @click="customerTab">
                    <template #title>
                      <p class="p-0 m-0" style="font-size: 17px">Customers</p>
                    </template>
                  </b-tab>
                  <b-tab class="text-color" @click="SupplierTab">
                    <template #title>
                      <p class="p-0 m-0" style="font-size: 17px">Suppliers</p>
                    </template>
                  </b-tab>
                </b-tabs>
              </div>
            </b-row>

            <b-card
              no-body
              class="shadow-sm border-0 table-container-card w-100"
            >
              <!-- table actions row -->
              <b-row
                class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3"
                no-gutters
              >
                <!-- search input column -->
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-4 px-lg-0 py-2 py-lg-0"
                  cols="12"
                  md="4"
                >
                  <!-- table search input -->
                  <b-input-group class="search-input-group">
                    <b-form-input
                      class="search-input border-0"
                      type="text"
                      :placeholder="
                        isQuote ? 'Search Quote#' : 'Search invoice#'
                      "
                      v-model="searchTable"
                      @keyup.native.enter="searchFn"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-row
                        class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light"
                        no-gutters
                      >
                        <b-icon
                          class="text-main-green"
                          icon="search"
                          size="lg"
                          aria-label="Search"
                          font-scale="1.2"
                        ></b-icon>
                      </b-row>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>

                <b-col
                  class="d-flex flex-row align-items-end justify-content-end mr-2 py-2 py-lg-0"
                  cols="12"
                  md="5"
                >
                  <b-button
                    v-if="!isQuote"
                    variant="outline-primary"
                    class="d-flex flex-row align-items-center justify-content-center invoice-buy-btn mx-4 my-2 my-lg-0 px-4 py-3 border rounded-lg border-primary"
                    pill
                    @click="topupInvoices()"
                    :disabled="this.getUserRole.role.includes('User')"
                    :style="
                      !this.getUserRole.role.includes('User')
                        ? 'cursor: pointer'
                        : 'cursor: not-allowed'
                    "
                  >
                    <span class="d-inline"> Buy more invoices </span>
                  </b-button>
                  <b-button
                    variant="main-green"
                    class="d-flex flex-row align-items-center justify-content-center mx-4 mx-md-0 my-2 my-lg-0 px-4 py-3 border-0 btn-invoice-create bg-main-green rounded-lg"
                    :to="!isQuote ? '/invoices/create' : '/quotes/create'"
                    pill
                    :disabled="this.getUserRole.role === 'User'"
                  >
                    <span class="d-inline">{{
                      isQuote ? "Create a Quote" : "Create an invoice"
                    }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <!-- <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100 my-0 my-md-2 px-3 py-0"
                no-gutters
              >
                <b-col>
                  <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="w-100 m-0 py-0"
                    toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
                    menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
                    left
                    no-caret
                    no-flip
                    text="Button text via Prop"
                  >
                    <template v-slot:button-content>
                      <b-button
                        block
                        id="customer-modal-btn"
                        variant="transparent"
                        class="w-100 d-flex  align-items-center justify-content-between
                   rounded-lg p-0 m-0"
                      >
                        <span class="text-primary">All customer</span>
                        <b-icon
                          icon="chevron-down"
                          class="text-primary"
                          font-scale="0.9"
                        ></b-icon>
                      </b-button>
                    </template>
                    <b-dropdown-item
                      @click="() => cusName(customers.text)"
                      href="#"
                      v-for="(customers, index) in customersList"
                      :key="index"
                    >
                      {{ customers.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row> -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100 my-0 my-md-2 px-3 py-0"
                no-gutters
              >
                <!-- filter column -->
                <b-col cols="12" md="6" lg="4" class="pr-0 pr-md-1">
                  <!-- <DropDown
                    id="customer"
                    PlaceHolder="All Customer"
                    :items="customersList"
                    @v-model="cusName"
                    :type="true"
                  /> -->
                  <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="w-100 m-0 py-0"
                    toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
                    menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
                    left
                    no-caret
                    no-flip
                    text="Button text via Prop"
                  >
                    <template v-slot:button-content>
                      <b-button
                        block
                        id="customer-modal-btn"
                        variant="transparent"
                        class="w-100 d-flex align-items-center justify-content-between rounded-lg p-0 m-0"
                      >
                        <span class="text-primary">{{
                          selectedCustomerName
                            ? selectedCustomerName
                            : customer
                            ? "All Customers"
                            : "All Suppliers"
                        }}</span>
                        <b-icon
                          icon="chevron-down"
                          class="text-primary"
                          font-scale="0.9"
                        ></b-icon>
                      </b-button>
                    </template>
                    <b-dropdown-item
                      @click="() => cusName(customers)"
                      href="#"
                      v-for="(customers, index) in customersList"
                      :key="index"
                    >
                      {{ customers.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- <FilterSelect
                    groupId="admin-type-group"
                    id="admin-type-group-select"
                    label="All Customers"
                    v-model="selectedCustomer"
                    :options="customersList"
                    form="admin-registration-form"
                    class="mt-0 customer-filter"
                  ></FilterSelect> -->
                </b-col>
                <!-- filter/create actions column -->
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                  class="pl-0 pl-md-1 pr-0 pr-lg-1"
                >
                  <!-- <FilterSelect
                    groupId="admin-type-group"
                    id="admin-type-group-select"
                    label="All Status"
                    v-model="selectedStatus"
                    :options="statusList"
                    form="admin-registration-form"
                    class="mt-0 status-filter"
                  ></FilterSelect> -->
                  <!-- <DropDown
                    id="status_invoice"
                    PlaceHolder="All Status"
                    :items="statusList"
                    :type="true"
                    @v-model="getStatus"
                  /> -->
                  <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="w-100 m-0 py-0"
                    toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
                    menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
                    left
                    no-caret
                    no-flip
                    text="Button text via Prop"
                  >
                    <template v-slot:button-content>
                      <b-button
                        block
                        id="customer-modal-btn"
                        variant="transparent"
                        class="w-100 d-flex align-items-center justify-content-between rounded-lg p-0 m-0"
                      >
                        <span class="text-primary">{{
                          selectedStatusName ? selectedStatusName : "All Status"
                        }}</span>
                        <b-icon
                          icon="chevron-down"
                          class="text-primary"
                          font-scale="0.9"
                        ></b-icon>
                      </b-button>
                    </template>
                    <b-dropdown-item
                      @click="() => getStatus(status)"
                      href="#"
                      v-for="(status, index) in statusList"
                      :key="index"
                    >
                      {{ status.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
                <!-- <b-col cols="12" lg="4" class="mb-2 mb-md-1 pr-md-1 px-0 px-md-1">
                  <b-input-group class="mb-2">
                    <b-form-datepicker
                      id="custom-start-date"
                      v-model="startDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      locale="en-UK"
                      placeholder="From"
                      selected-variant="info"
                      today-variant="info"
                      class="rounded-0 bg-white shadow-none startdate-picker mb-md-0"
                      menu-class="bg-white border border-info rounded-lg custom-calendar-dropdown"
                      style="color: var(--info)"
                    >
                    </b-form-datepicker>
                    <b-form-datepicker
                      id="custom-end-date"
                      v-model="endDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      locale="en-UK"
                      style="color: var(--info)"
                      placeholder="To"
                      selected-variant="info"
                      today-variant="info"
                      class="rounded-0 bg-white shadow-none enddate-picker pl-0 pl-md-0"
                      menu-class="bg-white border border-info rounded-lg custom-calendar-dropdown"
                    >
                    </b-form-datepicker>
                  </b-input-group>
                </b-col> -->
                <b-col cols="12" md="6" lg="2" class="mb-3 mb-md-0 px-0">
                  <b-input-group>
                    <b-form-input
                      id="example-input"
                      :value="formatDate(startDate)"
                      type="text"
                      class="border-main-green date-picker"
                      placeholder="From"
                      autocomplete="off"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        class="date-picker-btn font-primary datepicker align-items-center"
                        v-model="startDate"
                        no-flip
                        size="sm"
                        button-only
                        button-variant="white"
                        locale="en-US"
                        right
                        nav-button-variant="main-green"
                        today-variant="main-green"
                        menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                        aria-controls="start-date-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col cols="12" md="6" lg="2" class="pl-0">
                  <b-input-group>
                    <b-form-input
                      id="example-input"
                      :value="formatDate(endDate)"
                      class="border-main-green date-picker"
                      type="text"
                      placeholder="To"
                      autocomplete="off"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        class="date-picker-btn font-primary datepicker align-items-center"
                        v-model="endDate"
                        :min="minEndDate"
                        no-flip
                        size="sm"
                        button-only
                        button-variant="white"
                        locale="en-US"
                        right
                        nav-button-variant="main-green"
                        today-variant="main-green"
                        menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                        aria-controls="start-date-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100 my-0 my-md-2 px-3 py-0"
                no-gutters
              >
                <!-- filter column -->
                <b-col cols="12" md="6" lg="4" class="pr-0 pr-md-1">
                  <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="w-100 m-0 py-0"
                    toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
                    menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 "
                    left
                    no-caret
                    no-flip
                    text="Button text via Prop"
                  >
                    <template v-slot:button-content>
                      <b-button
                        block
                        id="customer-modal-btn"
                        variant="transparent"
                        class="w-100 d-flex align-items-center justify-content-between rounded-lg p-0 m-0"
                      >
                        <span class="text-primary">{{
                          selectedAgeRange ? selectedAgeRange : "Select Age"
                        }}</span>
                        <b-icon
                          icon="chevron-down"
                          class="text-primary"
                          font-scale="0.9"
                        ></b-icon>
                      </b-button>
                    </template>
                    <div style="max-height: 300px; overflow-y: scroll">
                      <b-dropdown-item
                        @click="() => getAgeRange(selectedAgeRange)"
                        href="#"
                        v-for="(selectedAgeRange, index) in AgeRangeOptions"
                        :key="index"
                      >
                        {{ selectedAgeRange }}
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </b-col>
                <b-col
                  class="d-flex flex-row align-items-center justify-content-end"
                >
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-end w-100 px-3 py-1"
                    no-gutters
                    v-if="computedFilterValidations"
                  >
                    <b-button
                      class="text-white mx-2 my-2 my-lg-0 py-1 rounded-lg apply-filters-btn"
                      pill
                      @click="applyFiltersFn"
                    >
                      <span class="d-inline px-2">Apply</span>
                    </b-button>
                    <b-link
                      @click="clearFilterFn"
                      class="text-left font-primary text-main-green font-weight-bold load-more-btn"
                      >Clear all
                    </b-link>
                  </b-row>
                </b-col>
              </b-row>

              <b-row
                class="d-flex flex-row align-items-start justify-content-center w-100 mb-3 mb-md-4 px-1 py-1"
                no-gutters
              >
                <b-tabs
                  active-nav-item-class="font-weight-bold text-main-green"
                  class="invoice-tabs"
                  nav-class="ml-3 tab-wrapper"
                  content-class="mt-3"
                >
                  <!-- table row -->
                  <b-tab title-link-class="tab-nav-link" lazy>
                    <template #title>
                      <div class="d-flex align-items-center">
                        Sent
                        <div
                          class="ml-2 px-2 py-1 font-weight-bold text-small active-circle"
                        >
                          {{ invoicesList.sentInvoices.totalRows }}
                        </div>
                      </div>
                    </template>
                    <InvoicesSentList
                      v-if="!isQuote"
                      :invoiceItems="invoicesList.sentInvoices.items"
                      :isLoading="invoicesList.sentInvoices.isLoading"
                      :totalRows="invoicesList.sentInvoices.totalRows"
                      @loadPage="loadsentInvoices"
                      @reloadFn="reloadPage"
                    />
                    <QuoteList
                      v-else
                      :quote-items="invoicesList.sentInvoices.items"
                      :isLoading="invoicesList.sentInvoices.isLoading"
                      :totalRows="invoicesList.sentInvoices.totalRows"
                      @loadPage="loadSentQuotes"
                      @reloadFn="reloadPage"
                    />
                  </b-tab>
                  <b-tab title-link-class="tab-nav-link" lazy>
                    <template #title>
                      <div class="d-flex align-items-center">
                        Unsent
                        <div
                          class="ml-2 px-2 py-1 font-weight-bold text-small active-circle"
                        >
                          {{ invoicesList.unpaidInvoices.totalRows }}
                        </div>
                      </div>
                    </template>

                    <InvoicesUnpaidList
                      v-if="!isQuote"
                      :invoiceItems="invoicesList.unpaidInvoices.items"
                      :isLoading="invoicesList.unpaidInvoices.isLoading"
                      :totalRows="invoicesList.unpaidInvoices.totalRows"
                      @loadPage="loadUnpaidInvoices"
                      @reloadFn="reloadPage"
                    />
                    <QuoteList
                      v-else
                      :quote-items="invoicesList.unpaidInvoices.items"
                      :isLoading="invoicesList.unpaidInvoices.isLoading"
                      :totalRows="invoicesList.unpaidInvoices.totalRows"
                      @loadPage="loadSentQuotes"
                      @reloadFn="reloadPage"
                    />
                  </b-tab>
                  <b-tab title-link-class="tab-nav-link" lazy>
                    <template #title>
                      <div class="d-flex align-items-center">
                        Draft
                        <div
                          class="ml-2 px-2 py-1 font-weight-bold text-small active-circle"
                        >
                          {{ invoicesList.draftInvoices.totalRows }}
                        </div>
                      </div>
                    </template>

                    <InvoicesDraftList
                      v-if="!isQuote"
                      :invoiceItems="invoicesList.draftInvoices.items"
                      :isLoading="invoicesList.draftInvoices.isLoading"
                      :totalRows="invoicesList.draftInvoices.totalRows"
                      @loadPage="loadDraftInvoices"
                      @reloadFn="reloadPage"
                    />
                    <QuoteList
                      v-else
                      :quote-items="invoicesList.draftInvoices.items"
                      :isLoading="invoicesList.draftInvoices.isLoading"
                      :totalRows="invoicesList.draftInvoices.totalRows"
                      @loadPage="loadDraftQuotes"
                      @reloadFn="reloadPage"
                    />
                  </b-tab>
                  <b-tab title-link-class="tab-nav-link" lazy active>
                    <template #title>
                      <div class="d-flex align-items-center">
                        All
                        <div
                          class="ml-2 px-2 py-1 font-weight-bold text-small active-circle"
                        >
                          {{ invoicesList.allInvoices.totalRows }}
                        </div>
                      </div>
                    </template>
                    <InvoicesAllList
                      v-if="!isQuote"
                      :customer="customer"
                      :invoiceItems="invoicesList.allInvoices.items"
                      :isLoading="invoicesList.allInvoices.isLoading"
                      :totalRows="invoicesList.allInvoices.totalRows"
                      @loadPage="loadAllInvoices"
                      @reloadFn="reloadPage"
                    />
                    <QuoteList
                      v-else
                      :customer="customer"
                      :quote-items="invoicesList.allInvoices.items"
                      :isLoading="invoicesList.allInvoices.isLoading"
                      :totalRows="invoicesList.allInvoices.totalRows"
                      @loadPage="loadAllInvoices"
                      @reloadFn="reloadPage"
                    />
                  </b-tab>
                </b-tabs>
              </b-row>
            </b-card>
          </b-row>

          <!-- <DateFilter
            :isShow="isShowDateFilterModal"
            @onClose="onCloseDateFilterModal"
            @getDateRange="filterUsersByDate"
          /> -->
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import InvoicesAllList from "@/components/Invoices/InvoicesAllList";
import InvoicesSentList from "@/components/Invoices/InvoicesSentList";
import InvoicesUnpaidList from "@/components/Invoices/InvoicesUnpaidList";
import InvoicesDraftList from "@/components/Invoices/InvoicesDraftList";
import { checkStripAccountStatus } from "@/services/stripe.service";
// import FilterSelect from "@/components/Form/FilterSelect";
// import DropDown from "@/components/Form/DropDown";

// services
import { GetAllCustomers, GetAllSuppliers } from "@/services/customers.service";

import {
  InvoiceAnalytics,
  // GetUnpaidInvoices,
  GetUnsentInvoices,
  GetDraftInvoices,
  GetAllInvoices,
  GetAllSentInvoices,
  QuoteAnalytics,
  GetPackageLimits,
  GetTopUpLink,
} from "@/services/invoice.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import QuoteList from "@/components/Quotes/QuoteList";
import { formatNumbers } from "@/util/formatNumbers.js";
import { mapGetters } from "vuex";

// components
//   import DateFilter from "@/components/Modals/DateFilter";
//   import {
//     GetUsersByCountry,
//     GetUsersByDate,
//     ExportAllUsers,
//     ExportUsersByDate,
//     ExportAllUSerByCountry,
//     ExportUsersByDateByCountry,
//     GetUsersByDateByCountry,
//   } from "@/services/users.service";

export default {
  name: "Invoices",
  components: {
    QuoteList,
    HomeLayout,
    InvoicesAllList,
    InvoicesDraftList,
    InvoicesUnpaidList,
    InvoicesSentList,
    // DropDown,
    //   DateFilter,
  },
  created() {
    this.clearFilterFn();
  },
  data() {
    return {
      // invoices tabs
      customer: true,
      tabIndex: 0,
      invoicesList: {
        allInvoices: {
          items: [],
          count: 0,
          page: 1,
          limit: 0,
          isLoading: false,
          totalRows: 0,
          showBanner: true,
        },
        draftInvoices: {
          items: [],
          count: 0,
          page: 1,
          limit: 0,
          isLoading: false,
          totalRows: 0,
        },
        unpaidInvoices: {
          items: [],
          count: 0,
          page: 1,
          limit: 0,
          isLoading: false,
          totalRows: 0,
        },
        sentInvoices: {
          items: [],
          count: 0,
          page: 1,
          limit: 0,
          isLoading: false,
          totalRows: 0,
        },
      },
      // filters
      selectedCustomer: undefined,
      selectedCustomerName: undefined,
      searchTable: null,
      selectedStatus: undefined,
      startDate: undefined,
      endDate: undefined,
      isFiltersApplied: false,
      // AgeRange: undefined,
      selectedAgeRange: undefined,
      AgeRangeOptions: [
        "0 - 30 Days",
        "31 - 60 Days",
        "61 - 90 Days",
        "91 - 120 Days",
        "120+ Days",
      ],
      customersList: [],
      selectedStatusName: undefined,
      accountStatus: false,
      statusList: [
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Unsent",
          value: "unsent",
        },
        {
          text: "Sent",
          value: "sent",
        },
        // {
        //   text: "Viewed",
        //   value: "viewed",
        // },
        // {
        //   text: "Partial",
        //   value: "partial",
        // },
        {
          text: "Paid",
          value: "paid",
        },
        {
          text: "Overdue",
          value: "overdue",
        },
      ],
      isShowDateFilterModal: false,
      isAnalyticsLoading: false,
      overdue: false,
      isLoading: false,
      analytics: {
        // amountOverdue: "LKR 300.00",
        // monthOverdue: "LKR 2000.00",
        // avgTime: "2 days",
        // upcomingPayout: "LKR 500.00",
      },
      // table pagination controls
      currency: localStorage.getItem("CURRENCY"),
      limits: "",
      invoiceNumbers: [],
    };
  },
  async mounted() {
    const { data } = await checkStripAccountStatus();

    this.accountStatus = data.status;
    await this.loadCurrency();
    this.initInvoiceAnalyticsFn();
    this.initGetAllInvoicesFn();
    this.initGetUnpaidInvoices();
    this.initGetDraftInvoicesFn();
    this.getCustomersListFn();
    this.getPackageLimits();
    this.initGetAllSentInvoicesFn();
  },
  watch: {
    searchTable() {
      this.searchFn();
    },
    startDate() {
      // reset the start date when end date changes
      this.endDate = "";
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole",
    }),
    minEndDate() {
      return this.startDate;
    },
    computedFilterValidations() {
      if (
        this.selectedCustomer ||
        this.searchTable ||
        this.selectedStatus ||
        this.startDate ||
        this.endDate ||
        this.selectedAgeRange
      ) {
        return true;
      }
      if (this.isFiltersApplied && !this.overdue) this.clearFilterFn();
      return false;
    },
    isQuote() {
      return window.location.href.toString().includes("quotes");
    },
  },
  methods: {
    // selectItem(val) {
    //   console.log("🚀 ~ file: Invoices.vue:738 ~ dropItem ~ val:", val);
    // },
    customerTab() {
      this.customer = true;
      this.reloadPage();
    },
    SupplierTab() {
      this.customer = false;
      this.reloadPage();
    },
    async topupInvoices() {
      const res = await GetTopUpLink();
      window.location.replace(res.data);
      console.log(res);
    },
    async getPackageLimits() {
      const res = await GetPackageLimits();
      this.limits = res.data;
    },
    cusName(val) {
      this.selectedCustomer = val.value;
      this.selectedCustomerName = val.text;
    },
    getStatus(val) {
      this.selectedStatus = val.value;
      this.selectedStatusName = val.text;
    },
    numberFormat(val) {
      return formatNumbers(val);
    },
    loadAllQuotes() {
      this.reloadPage();
    },
    loadDraftQuotes() {
      this.reloadPage();
    },
    loadSentQuotes() {
      this.reloadPage();
    },
    async initInvoiceAnalyticsFn() {
      try {
        this.isAnalyticsLoading = true;
        if (!this.isQuote) {
          let { data, status } = await InvoiceAnalytics(this.customer);
          console.log(
            "🚀 ~ file: Invoices.vue:1038 ~ initInvoiceAnalyticsFn ~ data:",
            data
          );
          if (status == 200) {
            //   console.log("analytics", data);
            this.isAnalyticsLoading = false;
            this.analytics = {
              amountOverdue: data.totaloverdue[0].total,
              monthOverdue: data.totaloverduebymonth[0].total,
              avgTime: data.totaldays[0].days,
            };
          } else {
            this.isAnalyticsLoading = false;
          }
        } else {
          let { data, status } = await QuoteAnalytics();
          console.log(data);
          if (status === 200) {
            this.isAnalyticsLoading = false;
            this.analytics = {
              unsent: data.totalunsent,
              sent: data.totalsent,
              totalUnsentAmount: data.totalunsentamount
                ? parseFloat(data.totalunsentamount).toFixed(2)
                : "0.00",
              totalSentAmount: data.totalsentamount
                ? parseFloat(data.totalsentamount).toFixed(2)
                : "0.00",
            };
          }
        }
      } catch (error) {
        this.isAnalyticsLoading = false;
        console.log(error);
      }
    },
    async getCustomersListFn() {
      try {
        // get all customers list from API
        let res;
        if (this.customer) {
          let { data } = await GetAllCustomers();
          res = data;
        } else {
          let { data } = await GetAllSuppliers();
          res = data;
        }
        this.customersList = res.results.results.map((x) => {
          return {
            text: x.name,
            value: x._id,
          };
        });
        console.log("customersList", this.customersList);
      } catch (error) {
        console.log(error);
      }
    },
    calTotal(itemList) {
      const total = itemList.reduce(function (total, item) {
        const tax = (item.price * item.quantity * item.tax_per_item) / 100;
        const preTaxAmount = item.price * item.quantity;
        const subTotal = tax + preTaxAmount;
        return total + subTotal;
      }, 0);
      return total;
    },
    async initGetAllInvoicesFn() {
      try {
        this.invoicesList.allInvoices.isLoading = true;
        let { data } = await GetAllInvoices(
          this.selectedCustomer,
          this.searchTable,
          this.selectedStatus,
          this.startDate,
          this.endDate,
          undefined,
          this.invoicesList.allInvoices.perPage,
          this.invoicesList.allInvoices.currentPage,
          this.selectedAgeRange,
          !this.isQuote,
          this.customer
        );
        
        this.invoicesList.allInvoices.totalRows = data.results.total;
        this.invoiceNumbers = data.results.results.map(invoice => invoice.invoice_number);
        var InvoiceItems = data.results.results.map((x) => {
          return {
            invoiceId: x._id,
            statusDetails: {
              id: x._id,
              status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
              dueDate: x.due_date.split("T")[0],
            },
            dateDetails: {
              id: x._id,
              date: x.issued_date.split("T")[0],
            },
            numberDetails: {
              id: x._id,
              number: x.invoice_number,
            },
            customerDetails: {
              id: x._id,
              customerName: x.customer_name,
            },
            totalAmount: {
              id: x._id,
              totalAmount: this.calTotal(x.items),
              conversion_rate: x.conversion_rate,
              anchor_currency: x.anchor_currency,
              currency: x.currency,
            },
            amountDetails: {
              id: x._id,
              amountDue: x.amount_due,
              totalAmount: this.calTotal(x.items),
              conversion_rate: x.conversion_rate,
              anchor_currency: x.anchor_currency,
              currency: x.currency,
            },
            actions: {
              id: x._id,
              status: x.status,
              title: x.title,
              dueDate: x.due_date.split("T")[0],
            },
          };
        });
        if (this.overdue === true) {
          const today = new Date();
          InvoiceItems = InvoiceItems.filter((item) => {
            const dueDate = new Date(item.statusDetails.dueDate);
            return item.statusDetails.status !== "Paid" && dueDate < today;
          });
          this.invoicesList.allInvoices.totalRows = InvoiceItems.length;
          this.selectedStatus = "draft";
        }
        this.invoicesList.allInvoices.items = InvoiceItems;
        this.invoicesList.allInvoices.count = InvoiceItems.length;
        this.invoicesList.allInvoices.page = 2;
      } catch (error) {
        console.log(error);
      } finally {
        this.invoicesList.allInvoices.isLoading = false;
      }
    },
    async initGetAllSentInvoicesFn() {
      try {
        this.invoicesList.sentInvoices.isLoading = true;
        let { data } = await GetAllSentInvoices(
          this.selectedCustomer,
          this.searchTable,
          this.selectedStatus,
          this.startDate,
          this.endDate,
          undefined,
          this.invoicesList.sentInvoices.perPage,
          this.invoicesList.sentInvoices.currentPage,
          this.selectedAgeRange,
          !this.isQuote,
          this.customer
        );
        this.invoicesList.sentInvoices.totalRows = data.results.total;
        var InvoiceItems = data.results.results.map((x) => {
          return {
            invoiceId: x._id,
            statusDetails: {
              id: x._id,
              status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
              dueDate: x.due_date.split("T")[0],
            },
            dateDetails: {
              id: x._id,
              date: x.issued_date.split("T")[0],
            },
            numberDetails: {
              id: x._id,
              number: x.invoice_number,
            },
            customerDetails: {
              id: x._id,
              customerName: x.customer_name,
            },
            totalAmount: {
              id: x._id,
              totalAmount: this.calTotal(x.items),
              conversion_rate: x.conversion_rate,
              anchor_currency: x.anchor_currency,
              currency: x.currency,
            },
            amountDetails: {
              id: x._id,
              amountDue: x.amount_due,
              totalAmount: this.calTotal(x.items),
              conversion_rate: x.conversion_rate,
              anchor_currency: x.anchor_currency,
              currency: x.currency,
            },
            actions: {
              id: x._id,
              status: x.status,
              title: x.title,
              dueDate: x.due_date.split("T")[0],
            },
          };
        });
        this.invoicesList.sentInvoices.items = InvoiceItems;
        this.invoicesList.sentInvoices.count = InvoiceItems.length;
        this.invoicesList.sentInvoices.page = 2;
      } catch (error) {
        console.log(error);
      } finally {
        this.invoicesList.sentInvoices.isLoading = false;
      }
      console.log(
        "🚀 ~ file: Invoices.vue:929 ~ initGetsentInvoicesFn ~ this.selectedCustomer:",
        this.selectedCustomer
      );
    },
    async initGetUnpaidInvoices() {
      const start = Date.now();
      console.log("date", start);
      try {
        this.invoicesList.unpaidInvoices.isLoading = true;
        let { data } = await GetUnsentInvoices(
          // this.selectedCustomer,
          // this.searchTable,
          // this.startDate,
          // this.endDate,
          // this.invoicesList.unpaidInvoices.perPage,
          // this.invoicesList.unpaidInvoices.currentPage,
          // this.selectedAgeRange,
          // !this.isQuote
          this.selectedCustomer,
          this.searchTable,
          this.selectedStatus,
          this.startDate,
          this.endDate,
          undefined,
          this.invoicesList.unpaidInvoices.perPage,
          this.invoicesList.unpaidInvoices.currentPage,
          this.selectedAgeRange,
          !this.isQuote,
          this.customer
        );
        this.invoicesList.unpaidInvoices.totalRows = data.results.total;
        console.log("results", data.results);
        var InvoiceItems = data.results.results.map((x) => {
          return {
            invoiceId: x._id,
            statusDetails: {
              id: x._id,
              status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
              dueDate: x.due_date.split("T")[0],
            },
            overdue: {
              id: x._id,
              dueDate: x.due_date.split("T")[0],
              difference: this.getDateDifference(x.due_date.split("T")[0]),
            },
            dateDetails: {
              id: x._id,
              date: x.issued_date.split("T")[0],
            },
            numberDetails: {
              id: x._id,
              number: x.invoice_number,
            },
            customerDetails: {
              id: x._id,
              customerName: x.customer_name,
            },
            totalAmount: {
              id: x._id,
              totalAmount: x.total_amount,
              conversion_rate: x.conversion_rate,
              anchor_currency: x.anchor_currency,
              currency: x.currency,
            },
            amountDetails: {
              id: x._id,
              amountDue: x.amount_due,
              totalAmount: x.total_amount,
              conversion_rate: x.conversion_rate,
              anchor_currency: x.anchor_currency,
              currency: x.currency,
            },
            actions: {
              id: x._id,
              status: x.status,
              title: x.title,
              dueDate: x.due_date.split("T")[0],
            },
          };
        });

        this.invoicesList.unpaidInvoices.items = InvoiceItems;
        this.invoicesList.unpaidInvoices.count = InvoiceItems.length;
        this.invoicesList.unpaidInvoices.page = 2;
      } catch (error) {
        console.log(error);
      } finally {
        this.invoicesList.unpaidInvoices.isLoading = false;
      }
    },
    async initGetDraftInvoicesFn() {
      try {
        this.invoicesList.draftInvoices.isLoading = true;
        let { data } = await GetDraftInvoices(
          // this.selectedCustomer,
          // this.searchTable,
          // this.startDate,
          // this.endDate,
          // this.invoicesList.draftInvoices.perPage,
          // this.invoicesList.draftInvoices.currentPage,
          // !this.isQuote
          this.selectedCustomer,
          this.searchTable,
          this.selectedStatus,
          this.startDate,
          this.endDate,
          undefined,
          this.invoicesList.allInvoices.perPage,
          this.invoicesList.allInvoices.currentPage,
          this.selectedAgeRange,
          !this.isQuote,
          this.customer
        );
        this.invoicesList.draftInvoices.totalRows = data.results.total;

        var InvoiceItems = data.results.results.map((x) => {
          return {
            invoiceId: x._id,
            statusDetails: {
              id: x._id,
              status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
              dueDate: x.due_date.split("T")[0],
            },
            dateDetails: {
              id: x._id,
              date: x.issued_date.split("T")[0],
            },
            numberDetails: {
              id: x._id,
              number: x.invoice_number,
            },
            customerDetails: {
              id: x._id,
              customerName: x.customer_name,
            },
            totalAmount: {
              id: x._id,
              totalAmount: x.total_amount,
              conversion_rate: x.conversion_rate,
              anchor_currency: x.anchor_currency,
              currency: x.currency,
            },
            amountDetails: {
              id: x._id,
              amountDue: x.amount_due,
              totalAmount: x.total_amount,
              conversion_rate: x.conversion_rate,
              anchor_currency: x.anchor_currency,
              currency: x.currency,
            },
            actions: {
              id: x._id,
              status: x.status,
              dueDate: x.due_date.split("T")[0],
            },
          };
        });
        this.invoicesList.draftInvoices.items = InvoiceItems;
        this.invoicesList.draftInvoices.count = InvoiceItems.length;
        this.invoicesList.draftInvoices.page = 2;
      } catch (error) {
        console.log(error);
      } finally {
        this.invoicesList.draftInvoices.isLoading = false;
      }
    },
    applyFiltersFn() {
      // resetting pagination
      this.invoicesList.allInvoices.currentPage = 1;
      this.isFiltersApplied = true;
      if (this.selectedStatus == "overdue") {
        this.selectedStatus = undefined;
        this.overdue = true;
        this.initGetAllInvoicesFn();
        this.initGetUnpaidInvoices();
        this.initGetDraftInvoicesFn();
        this.initGetAllSentInvoicesFn();
      } else {
        this.overdue = false;
        this.initGetAllInvoicesFn();
        this.initGetUnpaidInvoices();
        this.initGetDraftInvoicesFn();
        this.initGetAllSentInvoicesFn();
      }
    },
    clearFilterFn() {
      this.selectedCustomer = undefined;
      this.searchTable = undefined;
      this.selectedStatus = undefined;
      this.selectedStatusName = undefined;
      this.startDate = undefined;
      this.endDate = undefined;
      this.isFiltersApplied = false;
      this.overdue = false;
      this.selectedAgeRange = undefined;
      this.selectedCustomerName = undefined;
      this.initGetAllInvoicesFn();
      this.initGetUnpaidInvoices();
      this.initGetDraftInvoicesFn();
      this.initGetAllSentInvoicesFn();
    },
    getDateDifference(inputDate, dateToCompareWith = new Date()) {
      const date1 = new Date(inputDate);
      const date2 = new Date(dateToCompareWith);
      const diffTime = date1 - date2;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return diffDays;
    },
    loadAllInvoices(value) {
      if (value) {
        const { currentPage, perPage } = value;
        this.invoicesList.allInvoices.currentPage = currentPage;
        this.invoicesList.allInvoices.perPage = perPage;
        this.initGetAllInvoicesFn();
      }
    },
    loadUnpaidInvoices(value) {
      if (value) {
        const { currentPage, perPage } = value;
        this.invoicesList.unpaidInvoices.currentPage = currentPage;
        this.invoicesList.unpaidInvoices.perPage = perPage;
        this.initGetUnpaidInvoices();
      }
    },
    loadsentInvoices(value) {
      if (value) {
        const { currentPage, perPage } = value;
        this.invoicesList.sentInvoices.currentPage = currentPage;
        this.invoicesList.sentInvoices.perPage = perPage;
        this.initGetAllSentInvoicesFn();
      }
    },
    loadDraftInvoices(value) {
      if (value) {
        const { currentPage, perPage } = value;
        this.invoicesList.draftInvoices.currentPage = currentPage;
        this.invoicesList.draftInvoices.perPage = perPage;
        this.initGetDraftInvoicesFn();
      }
    },
    async loadCurrency() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    reloadPage() {
      this.invoicesList.allInvoices.currentPage = 1
      this.invoicesList.draftInvoices.currentPage = 1
      this.invoicesList.sentInvoices.currentPage = 1
      this.invoicesList.unpaidInvoices.currentPage = 1
      this.loadCurrency();
      this.initInvoiceAnalyticsFn();
      this.initGetAllInvoicesFn();
      this.initGetUnpaidInvoices();
      this.initGetDraftInvoicesFn();
      this.getCustomersListFn();
      this.getPackageLimits();
      this.initGetAllSentInvoicesFn();
      this.clearFilterFn();
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
    getAgeRange(val) {
      this.selectedAgeRange = val;
      this.selectedAgeRange = val;
    },
    searchFn() {
      // this.searchCustomers(1);
      if (this.searchTable && this.searchTable.length > 0) {
        // call the init function to load the data based on the search query
        // pass 1 into the function to reset the current page to 1, before executing the search to ensure the search doesn't get hindered by the pagination
        this. applyFiltersFn();
      }else{
        this.initFn();
      }
    },
  },
};
</script>

<style scoped>
.text-color .active {
  color: red;
}

.invoice-buy-btn {
  margin-right: 10px;
  height: 50px;
  width: 180px;
}

.btn-invoice-create {
  height: 50px;
  width: 180px;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.date-picker-btn {
  border: 1.8px solid;
  border-color: #0b9b7d;
  border-radius: 0px 3px 3px 0px;
}

/* insights card */
.insight-cards-row {
  padding: 25px;
  height: 150px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
}

/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .customer-filter {
  /* min-width: 300px; */
  /* width: 50px; */
}

.status-filter .form-group >>> select.custom-select {
  /* min-width: 180px; */
  /* width: 50px; */
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

.table-card-row .table-container-card >>> .apply-filters-btn {
  transition: color 200ms ease-in;
  background-color: #0b9b7d;
  border: none;
}

.table-card-row .table-container-card .apply-filters-btn:hover {
  background-color: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  /* color: var(--primary); */
}

.table-container-row .table-container-card .load-more-btn {
  transition: color 200ms ease-in;
  color: #0b9b7d;
}

.table-container-row .table-container-card .load-more-btn:hover {
  color: #0b9b7d;
}

.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}

.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: #0b9b7d;
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 2px solid #0b9b7d;
}

.table-card-row
  .table-container-card
  >>> .invoice-tabs
  .tab-nav-link
  .active-circle {
  background-color: var(--light);
  color: #0b9b7d;
}

.table-card-row
  .table-container-card
  >>> .invoice-tabs
  .tab-nav-link.active
  .active-circle {
  background-color: #0b9b7d;
  color: white;
  border-radius: 50%;
}

.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  min-width: 200px;
  /* border-radius: 10px; */
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
  border-radius: 5px;
  background-color: #f7fbfc;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

/* .table-card-row .customer-dropdown {
  height: 40px;
  min-width: 270px;
  max-width: 350px;
} */
/* .table-card-row .status-dropdown {
  height: 40px;
  width: 200px;
  min-width: 270px;
  max-width: 350px;
} */
.table-card-row .date-picker {
  border-width: 1.8px;
  /* min-width: 150px;
  max-width: 200px; */
}

.table-card-row .startdate-picker {
  border: 1.8px solid #0b9b7d;
  border-radius: 5px;
}

.table-card-row .enddate-picker {
  border: 1.8px solid #0b9b7d;
  border-radius: 5px;
}

.table-card-row .date-picker::placeholder {
  color: #0b9b7d;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}

.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 8px;
  height: 8px;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item.active,
.table-card-rowdate
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

.table-card-row .export-dropdown >>> .export-dropdown-button {
  padding: 6px 20px;
}

.btn-invoice-create:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .insight-cards-row {
    height: 230px;
  }

  /* .table-card-row .customer-dropdown,
  .table-card-row .status-dropdown {
    min-width: auto;
    min-width: 200px;
    max-width: 350px;
  } */
  /* .table-card-row .search-input-group {
    width: 200px;
  } */
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: 400px;
  }

  /* .table-card-row .customer-dropdown,
  .table-card-row .status-dropdown {
    min-width: auto;
    width: 350px;
  } */
  /* .table-card-row .search-input-group {
    max-width: none;
    width: 500px;
  } */
}
</style>
